<template>
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2692 4.23529C13.2692 5.74331 12.4748 7.06721 11.2785 7.81764C15.0056 8.78093 17.7936 12.0517 18 16H0C0.206361 12.0516 2.99467 8.78076 6.7218 7.81755C5.52567 7.06712 4.73126 5.74325 4.73126 4.23529C4.73126 1.8962 6.64259 0 9.00023 0C11.3579 0 13.2692 1.8962 13.2692 4.23529ZM1.06145 15.0588H16.9385C16.2664 11.3139 12.9679 8.47059 9 8.47059C5.03212 8.47059 1.73358 11.3139 1.06145 15.0588ZM9.00023 7.52941C10.834 7.52941 12.3205 6.05457 12.3205 4.23529C12.3205 2.41602 10.834 0.941176 9.00023 0.941176C7.16649 0.941176 5.67992 2.41602 5.67992 4.23529C5.67992 6.05457 7.16649 7.52941 9.00023 7.52941Z" :fill="color"/>
  </svg>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'LoginIcon',
  props: {
    error: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const store = useStore()

    const color = computed(() => {
      if (props.error) {
        return '#FFFFFF'
      } else if (store.getters.getThemeSPA !== 'darkTheme') {
        return '#FFFFFF'
      } else {
        return '#585858'
      }
    })
    return {
      color
    }
  }
}
</script>
