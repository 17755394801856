<template>
  <div :class="{
    'input': true,
    'input__error': errorVolid
  }">
    <div :class="{
      'input__icon': true,
      'input__icon-active': active
    }">
      <slot />
    </div>
    <input
      class="input__item"
      :type="type"
      @input="change"
      :placeholder="placeholder"
    >
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: '',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  setup () {
    const store = useStore()
    const active = ref(false)
    /*
      value - переменная, чтобы отдать введенные данные в input во вне
      в компоненте AuthForm через $ref получаю это поле для отправки value
      в action SignIn
    */
    const value = ref('')

    const errorVolid = computed(() => store.getters.getSignInError)

    const change = (e) => {
      value.value = e.target.value
      if (e.target.value.length > 0) {
        active.value = true
      } else {
        active.value = false
      }
    }

    return {
      change,
      active,
      value,
      errorVolid
    }
  }
}
</script>

<style lang="scss" scoped>
.input__error {
  .input__icon {
    background-color: #EA252F;
    border: 1px solid var(--error-valid-input-text-color);
  }

  .input__item {
    color: var(--error-valid-input-text-color);
  }
}
.input {
  display: flex;
  align-items: center;
  height: 34px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 66px;
    background-color: var(--icon-input-background);
    border: 1px solid var(--form-input-icon-border-color);
    border-radius: 6px 0px 0px 6px;
    border-right: none;
    transition: background-color 0.5s;
  }

  &__icon-active {
    background-color: var(--icon-input-background-active);
  }

  &__item {
    height: 100%;
    width: 100%;
    padding-left: 7px;
    font-variant-numeric: lining-nums;
    font-size: 20px;
    color: var(--input-text-color);
    border: 1px solid var(--form-input-border-color);
    border-left: none;
    border-radius: 0px 6px 6px 0px;
    background-color: var(--input-background);
    transition: background-color 0.5s;
    outline: none;

    &::-webkit-input-placeholder {
      color: var(--placeholder-input-text-color);
    }

    &::-moz-placeholder {
      color: var(--placeholder-input-text-color);
    }/* Firefox 19+ */

    &:-moz-placeholder {
      color: var(--placeholder-input-text-color);
    }/* Firefox 18- */

    &:-ms-input-placeholder {
      color: var(--placeholder-input-text-color);
    }
  }
}
</style>
