export default {
  state: {
    theme: '',
    activeSettingsTheme: false
  },
  mutations: {
    SetTheme (state, val) {
      state.theme = val
    },
    SetThemeActiveSettings (state, val) {
      state.activeSettingsTheme = val
    }
  },
  actions: {
    setThemeActiveSettings ({ commit }, val) {
      commit('SetThemeActiveSettings', val)
    }
  },
  getters: {
    getThemeSPA: state => state.theme,
    getThemeActiveSettings: state => state.activeSettingsTheme
  }
}
