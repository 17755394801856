<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47717 0 0 4.47715 0 10C0 15.5228 4.47717 20 10 20ZM19 10C19 14.9706 14.9706 19 10 19V14C12.2091 14 14 12.2091 14 10C14 7.79086 12.2091 6 10 6V1C14.9706 1 19 5.02944 19 10ZM10 6C7.79089 6 6 7.79086 6 10C6 12.2091 7.79089 14 10 14V6Z" :fill="color"/>
  </svg>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'PasswordIcon',
  setup () {
    const store = useStore()

    const color = computed(() => {
      if (store.getters.getThemeSPA && localStorage.getItem('theme') === 'darkTheme') {
        return '#FFFFFF'
      } else {
        if (store.getters.getThemeActiveSettings) {
          return '#FFFFFF'
        } else {
          return '#585858'
        }
      }
    })
    return {
      color
    }
  }
}
</script>
