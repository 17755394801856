import axios from 'axios'
import router from '@/router/index'

export default {
  state: {
    signInError: false,
    token: null
  },

  mutations: {
    SetSignInError (state, val) {
      state.signInError = val
    }
  },

  actions: {
    SignIn ({ state }, val) {
      axios.post(window.URL_API + 'api/Account/SignIn', {
        Login: val.login,
        Password: val.password
      },
      { withCredentials: true })
        .then((res) => {
          if (res.data.Succeeded) {
            router.push('/')
            state.signInError = false
          } else {
            state.signInError = true
          }
        })
    },

    SignOut ({ commit, dispatch }) {
      axios.get(window.URL_API + 'api/Account/SignOut',
        { withCredentials: true })
        .then((res) => {
          dispatch('AuthVerification')
        })
    },

    AuthVerification () {
      axios.get(window.URL_API + 'api/Titul/Common/IsSupported',
        { withCredentials: true })
        .then(() => {
          router.push('/')
        })
        .catch(() => {
          router.push('/auth')
        })
    }
  },
  getters: {
    getSignInError: state => state.signInError,
    getToken: state => state.token
  }
}
