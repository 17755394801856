<template>
  <div id="app">
    <Preloader  v-if="preload"/>
    <router-view />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

import Preloader from './components/elements/Preloader.vue'

export default {
  name: 'app',
  components: {
    Preloader
  },
  setup () {
    const store = useStore()
    const preload = ref(store.getters.getPreloader)

    onMounted(() => {
      setTimeout(() => {
        preload.value = false
      }, 2000)
    })

    return {
      preload
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: $font-family-raleway;
  font-variant-numeric: lining-nums;
}
</style>
