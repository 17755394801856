<template>
  <button @click="$emit('clickBtn')" class="button">
    {{name}}
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  emits: ['clickBtn']
}
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  padding: 4px;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  background-color: var(--primary-background);
  border-radius: 6px;
  border: none;
  outline: none;
  transition: background-color 0.5s;
  cursor: pointer;

  &:focus {
    background-color: var(--focus-background-btn);
  }
}

@media(any-hover: hover) {
  .button:hover {
    background-color: var(--hover-background-btn);
  }
}
</style>
