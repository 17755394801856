<template>
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 12C10 12.9319 9.3623 13.715 8.5 13.937V16H7.5V13.937C6.6377 13.715 6 12.9319 6 12C6 10.8954 6.89551 10 8 10C9.10449 10 10 10.8954 10 12ZM9 12C9 12.5523 8.55273 13 8 13C7.44727 13 7 12.5523 7 12C7 11.4477 7.44727 11 8 11C8.55273 11 9 11.4477 9 12Z" :fill="color"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0C5.23828 0 3 2.23859 3 5V6H2C0.895508 6 0 6.89545 0 8V18C0 19.1046 0.895508 20 2 20H14C15.1045 20 16 19.1046 16 18V8C16 6.89545 15.1045 6 14 6H13V5C13 2.23859 10.7617 0 8 0ZM12 6V5C12 2.79083 10.209 1 8 1C5.79102 1 4 2.79083 4 5V6H12ZM2 7C1.44727 7 1 7.44769 1 8V18C1 18.5523 1.44727 19 2 19H14C14.5527 19 15 18.5523 15 18V8C15 7.44769 14.5527 7 14 7H2Z" :fill="color"/>
  </svg>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'PasswordIcon',
  props: {
    error: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const store = useStore()

    const color = computed(() => {
      if (props.error) {
        return '#FFFFFF'
      } else if (store.getters.getThemeSPA !== 'darkTheme') {
        return '#FFFFFF'
      } else {
        return '#585858'
      }
    })
    return {
      color
    }
  }
}
</script>
