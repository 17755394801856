<template>
  <div class="auth-form">
    <h1 class="auth-form__title">Вход</h1>
    <img src="@/assets/images/logo.svg" alt="">
    <h2 class="auth-form__subtitle">Титул-Веб</h2>
    <form class="form" @submit="submit">
      <Input
        ref="login"
        :class="{
          'form__input': true,
          'wibro': formErrors.errorInput
        }"
        placeholder="Логин"
      >
      <LoginIcon :error="formErrors.colorErrorIcon" /></Input>

      <Input
        type="password"
        ref="password"
        :class="{
          'form__input': true,
          'wibro': formErrors.errorInput
        }"
        placeholder="Пароль"
      >
      <PasswordIcon :error="formErrors.colorErrorIcon" /></Input>

      <span v-if="false" class="form__password">Забыли пароль?</span>
      <Btn name='Войти'/>
    </form>
    <div class="auth-form__bottom">
      <span class="auth-form__bottom-title">© ООО «Титул-2005»</span>
      <span class="auth-form__bottom-subtitle">г. Саратов</span>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, reactive } from 'vue'
import { useCookies } from 'vue3-cookies'

import Input from '@/components/ui/Input.vue'
import Btn from '@/components/ui/Button.vue'

import LoginIcon from '@/components/icons/LoginIcon.vue'
import PasswordIcon from '@/components/icons/PasswordIcon.vue'

export default {
  name: 'AuthForm',
  components: {
    Input,
    Btn,
    LoginIcon,
    PasswordIcon
  },
  setup () {
    const store = useStore()
    const { cookies } = useCookies()

    const formErrors = reactive({
      errorInput: false,
      colorErrorIcon: false
    })

    const login = ref(null)
    const password = ref(null)

    const submit = (e) => {
      store.commit('SetPreloader', true)
      e.preventDefault()

      cookies.remove('.AspNetCore.Identity.Application')

      store.dispatch('SignIn', {
        login: login.value.value,
        password: password.value.value
      })

      setTimeout(() => {
        formErrors.errorInput = formErrors.colorErrorIcon = store.getters.getSignInError
      }, 100)

      setTimeout(() => {
        formErrors.errorInput = false
      }, 400)
    }

    return {
      submit,
      login,
      password,
      formErrors
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-form {
  width: 506px;
  padding: 25px 0px 30px 0px;
  text-align: center;
  background-color: var(--auth-form-background);
  border: 1px solid var(--primary-border-color);
  border-radius: 12px;
  transition: background-color 0.5s;

  &__title {
    font-size: 32px;
    font-weight: 600;
    color: var(--title);
  }

  &__subtitle {
    margin-bottom: 35px;
    font-weight: 400;
    color: var(--title);
  }

  .form {
    width: 260px;
    margin: 0 auto 76px auto;

    &__password {
      display: inline-block;
      margin-bottom: 32px;
      font-size: 14px;
      font-style: italic;
      color: var(--auth-text-color);
      transition: color 0.5s;
    }

    &__input {
      &:first-child {
        margin-bottom: 14px;
      }

      &:nth-child(2) {
        margin-bottom: 68px;
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 500;
    color: var(--auth-text-color);
    transition: color 0.5s;

    &-title {
      font-size: 14px;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 10px;
    }
  }
}
.wibro {
    -webkit-animation: 0.1s tremor ease-out infinite;
    // animation: 0.1s tremor ease-out infinite;
    animation: 0.1s tremor ease-out infinite;
    animation-iteration-count: 4;
}
@-webkit-keyframes tremor {
    0%, 25% {
        left: -1px;
        top:-1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }
    50%, 100% {
        left: 1px;
        top: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}
@-moz-keyframes tremor {
    0%, 25% {
        left: -1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }
    50%, 100% {
        left: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}
@-ms-keyframes tremor {
    0%, 25% {
        left: -1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }
    50%, 100% {
        left: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}
@-o-keyframes tremor {
    0%, 25% {
        left: -1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }
    50%, 100% {
        left: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}
@keyframes tremor {
    0%, 25% {
        left: -1px;
        -webkit-transform: translateX(-4%);
        transform: translateX(-4%);
    }
    50%, 100% {
        left: 1px;
        -webkit-transform: translateX(4%);
        transform: translateX(4%);
    }
}
</style>
