import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import 'normalize.css/normalize.css'
import './assets/styles/app.scss'
import './config.js'

const app = createApp(App)

// способ обьявления глобальный переменных
// app.config.globalProperties.globalVar = {
//   var1: 'https://gis.titul2005.ru:1345/GisLayout',
//   var2: 'https://gis.titul2005.ru:1345/GisLayout'
// }

app.config.globalProperties.isDevelop = process.env.NODE_ENV === 'production' ? 0 : 1

app
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .mount('#app')
