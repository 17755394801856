<template>
  <div class="auth">
    <div v-if="false" class="auth-performance">
      <img src="@/assets/images/region.svg" alt="">
      <div class="auth-performance__text">
        <span class="auth-performance__text-title">Саратовская обл.</span>
        <span class="auth-performance__text-subtitle">г. Саратов</span>
      </div>
    </div>

    <img class="auth-gif" src="@/assets/gifs/presintation.gif" alt="">

    <Header />
    <div class="auth-block">
      <AuthForm />
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

import Header from '@/components/blocks/Header.vue'
import AuthForm from '@/components/blocks/AuthForm.vue'

export default {
  name: 'Auth',
  components: {
    Header,
    AuthForm
  },
  setup () {
    const store = useStore()

    onMounted(() => {
      setTimeout(() => {
        store.dispatch('AuthVerification')
      }, 100)
    })
  }
}
</script>

<style lang="scss" scoped>
.auth {
  &-gif {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100vh;
  }

  &-performance {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    width: 140px;
    height: 129px;
    padding: 8px 0px 8px 20px;
    overflow: hidden;
    background-color: var(--performance-background);
    border: 1px solid var(--performance-border-color);
    border-radius: 12px;
    animation: animation-name 5s ease-in-out;
    opacity: 0;

    &__text {
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      color: var(--performance-text-color);
      white-space: nowrap;
      animation: animation-nameTwo 2.5s ease-in-out;
      opacity: 0;

      &-title {
        font-size: 20px;
      }
    }
  }

  &-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 85px);
  }
}

@keyframes animation-nameTwo {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animation-name {
  0% {
    opacity: 0;
    width: 140px;
  }
  25% {
    opacity: 1;
    width: 500px;
  }
  50% {
    opacity: 1;
    width: 500px;
  }
  75% {
    opacity: 1;
    width: 140px;
  }
  100% {
    opacity: 0;
    width: 140px;
  }
}
</style>
