<template>
  <div class="box">
    <div class="loader-39">
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {
  }
}
</script>

<style lang="scss" scoped>
.box {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 125px;
  background: #fff;
  border-radius: 3px;
  transition: .3s color, .3s border, .3s transform, .3s opacity;
  z-index: 111;
  opacity: 0.99;
}

.loader-39 {
  position: relative;
  width: .15em;
  height: .15em;
  background-color: currentcolor;
  border-radius: 100%;
  animation: loader-39-1 30s infinite linear;

  &:before, &:after {
    content: '';
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:before {
    width: .3em;
    height: 1em;
    animation: loader-39-2 .8s linear infinite;
  }

  &:after {
    width: 1em;
    height: .3em;
    animation: loader-39-2 1.2s linear infinite;
  }
}

@keyframes loader-39-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-39-2 {
  0% {
    box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
  }
  25% {
    box-shadow: 0.04em 0.04em 0 0.02em currentcolor;
  }
  50% {
    box-shadow: -0.04em 0.04em 0 0.02em currentcolor;
  }
  75% {
    box-shadow: -0.04em -0.04em 0 0.02em currentcolor;
  }
  100% {
    box-shadow: 0.04em -0.04em 0 0.02em currentcolor;
  }
}
</style>
