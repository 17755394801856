import { createStore } from 'vuex'
import { getCurrentInstance } from 'vue'
import axios from 'axios'

import auth from './modules/auth'
import theme from './modules/themeSPA'

export default createStore({
  state: {
    preloader: true,
    projects: null
  },

  mutations: {
    SetPreloader (state, val) {
      state.preloader = val
    },
    SetLauncher (state, val) {
      state.projects = val
    }
  },

  actions: {
    ListProjects ({ commit, dispatch }) {
      axios.get(window.URL_API + 'api/Common/Launcher/Projects/' + getCurrentInstance().appContext.config.globalProperties.isDevelop,
        { withCredentials: true })
        .then((res) => {
          commit('SetLauncher', res.data.Data)
        })
    }
  },

  getters: {
    getPreloader: state => state.preloader,
    getListProjects: state => state.projects
  },

  modules: {
    auth,
    theme
  }
})
